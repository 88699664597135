<template>
  <div id="app">
    <div class="" style="height: 81px;width: 100%;"></div>
    <el-container>

      <el-header height="81px">

        <div class="head-left">
          <router-link to="/">
            <img src="/home/logo.png" alt="">
          </router-link>
          <i v-if="!isMobile">全国服务众包信息平台</i>
        </div>

        <div class="head-right">

          <p v-if="!isMobile">众包导航·常看常新·收藏本站·Ctrl+D!</p>
          <p>已收录<span>{{ webTotal }}</span>家平台，
            您是第<span>{{ visits }}</span>位访客</p>

        </div>


      </el-header>
      <el-main>
        <router-view />
      </el-main>

      <el-footer>
        <div class="e-f-1">
          <div class="e-f-1-left">
            <p class="p1">其他链接</p>
            <p>
              <span><a style="color:white;text-decoration: none;" href="https://www.gov.cn/"
                  target="_blank">中国政府网</a></span>
              <span><a style="color:white;text-decoration: none;" href="https://www.miit.gov.cn/"
                  target="_blank">工业和信息化部</a></span>
              <span><a style="color:white;text-decoration: none;" href="https://www.ccopyright.com.cn/"
                  target="_blank">中国版权保护中心</a></span>
            </p>
          </div>
          <div class="e-f-1-middle">

            <div class="card-1">

              <router-link to="/about">
                <div class="b-f">
                  <img src="../public/home/slsq.png" alt="">
                </div>
                <span>收录申请</span>
              </router-link>
            </div>
            <div class="card-1">
              <router-link to="/jyly">
                <div class="b-f">
                  <img src="../public/home/jyly.png" alt="">
                </div>
                <span>留言反馈</span>
              </router-link>

            </div>
          </div>
          <div class="e-f-1-right">
            <div class="card-1">
              <div class="b-f">
                <img src="../public/home/lxwm.png" alt="">
              </div>
              <span>联系我们</span>
            </div>
            <div class="card-1" v-if="!isMobile">
              <div class="b-f">
                <img src="../public/home/sjfx.png" alt="">
              </div>
              <span>手机分享</span>
            </div>
          </div>
        </div>
        <div class="e-f-2">
          Copyright © 2024 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank">&nbsp;&nbsp;蜀ICP备2021027962号-5</a>
        </div>
      </el-footer>
    </el-container>

  </div>
</template>

<script>
import { webNum } from './api/all'
export default {
  name: 'HomeView',
  data() {
    return {
      isMobile: null,
      webTotal: 0,
      visits: 0
    }
  },
  mounted() {
    this.getWebTotal()
  },
  methods: {
    getWebTotal() {
      webNum()
        .then(res => {
          this.webTotal = res.data[0]
          this.visits = res.data[1]
        })
    },
    clickHome() {
      this.$router.push({ name: 'home' })
    },
    isMobiles() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
    }
  },
  created() {
    this.isMobile = this.isMobiles()
  },
}
</script>

<style lang="less" scoped>
/* 媒体查询：手机端 */
@media screen and (max-width: 600px) {
  .el-header {
    overflow: hidden;
    box-sizing: border-box;
    // padding: 0 5%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background-color: #3EA9F6;
    top: 0;
    z-index: 99;

    .head-left {
      display: flex;
      font-size: 22px;
      color: #00349A;
      font-weight: bold;
      align-items: center;


    }

    i {
      font-size: 30px;
      color: #fff;
    }

    img {
      width: 66px;
      height: 38px;
      // margin-right: 47px;
    }


    .head-right {
      height: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 10px;

      p {
        margin: 0;
      }

      span {
        font-size: 14px;
        color: #FFCB63
      }
    }


  }

  .el-main {
    padding: 0;
  }


  .el-footer {
    padding: 0;
    width: 100%;
    background: #2A8ACF;
    height: 108px !important;
    position: fixed;
    bottom: 0;
    .e-f-1 {
      display: flex;
      justify-content: space-between;
      height: 81px;

      &-left {
        box-sizing: border-box;
        padding: 12px;
        display: flex;

        p {
          margin: 0;
          color: #fff;
        }

        .p1 {
          font-size: 12px;
          margin-right: 10px;
        }

        span {
          display: block;
          font-size: 10px;
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 5px;
        }
      }

      &-middle {
        display: flex;
        justify-content: center;

        .card-1 {
          font-size: 10px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;

          a {
            text-decoration: none;
            color: #fff;
          }

          .b-f {
            width: 35px;
            height: 35px;
            background-color: #fff;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 9px;

          }

          .b-f:nth-child(1) {
            margin-right: 10px;
          }

          img {
            width: 25px;
            height: 25px;

          }
        }
      }

      &-right {
        display: flex;
        justify-content: center;
        margin-right: 35px;

        .card-1 {
          font-size: 10px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .b-f {
            width: 35px;
            height: 35px;
            border-radius: 7px;

            margin-bottom: 9px;
          }

          img {
            width: 35px;
            height: 35px;

          }
        }
      }

    }

    .e-f-2 {
      color: rgba(255, 255, 255, 0.7);
      background: #1D6598;
      width: 100%;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

/* 媒体查询：PC端 */
@media screen and (min-width: 601px) {
  .el-header {
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 2%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background-color: #3EA9F6;
    top: 0;
    z-index: 99;

    .head-left {
      display: flex;
      font-size: 22px;
      color: #00349A;
      font-weight: bold;
      align-items: center;


    }

    i {
      font-size: 30px;
      color: #fff;
    }

    img {
      width: 156px;
      height: 70px;
      margin-right: 47px;
    }


    .head-right {
      height: 100%;
      text-align: right;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;

      p {
        margin: 0;
      }

      P:nth-child(1) {
        margin-bottom: 8px;
      }

      span {
        font-size: 22px;
        color: #FFCB63
      }
    }


  }

  .el-main {
    padding: 0;
  }


  .el-footer {
    padding: 0;
    background: #2A8ACF;
    height: 180px !important;

    .e-f-1 {
      display: flex;
      justify-content: space-evenly;

      >div {
        width: 25%;
      }

      &-left {

        .p1 {
          margin-bottom: 16px;
        }

        p {
          font-size: 24px;
          color: #fff;
        }

        span {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.7);
          margin-right: 15px;
        }
      }

      &-middle {
        display: flex;

        .card-1 {
          font-size: 16px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;

          a {
            text-decoration: none;
            color: #fff;
          }

          .b-f {
            width: 62px;
            height: 62px;
            background-color: #fff;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 9px;
          }

          .b-f:nth-child(1) {
            margin-right: 60px;
          }

          img {
            width: 53px;
            height: 53px;

          }
        }
      }

      &-right {
        display: flex;

        .card-1 {
          font-size: 16px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .b-f {

            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 9px;
          }

          .b-f:nth-child(1) {
            margin-right: 50px;
          }

          img {
            width: 62px;
            height: 62px;

          }
        }
      }

    }

    .e-f-2 {
      color: rgba(255, 255, 255, 0.7);
      background: #1D6598;
      width: 100%;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}</style>
