<template>
	<div ref="element" class="home">

		<div class="main">
			<div class="main-left" v-if="!isMobile">
				<div class="tab-head">
					<div class="tab-head-right">
						<div class="tab-1" @click="clickTab('全部')" :id="tabName === '全部' ? 'cTab' : ''">
							<img src="../../public/home/all.png" alt="">
							全部
						</div>
						<div class="tab-1" v-for="( item, index ) in  tabList " :id="item.name === tabName ? 'cTab' : ''"
							:key="index" @click="clickTab(item.name)">
							<img :src="item.icon" alt="">
							{{ item.name }}
						</div>


					</div>
				</div>
			</div>
			<div class="main-right">
				<div class="head-2" v-if="!isMobile">
					<div class="img" v-for=" item  in  guanggaolist ">
						<img @click="clickTp1(item.network_addr)" :src="item.logo_image" alt="">
						<div class="infomercial">
							{{item.type}}
						</div>
					</div>

				</div>

				<div class="head-1">
					<div>
						<el-input placeholder="维修｜跑腿｜软件开发..." v-model="input">
							<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
							<el-button @click="searchName" style="border-radius:0 26px 26px 0;" slot="append"
								icon="el-icon-search"></el-button>
						</el-input>
						<!-- <el-input suffix-icon="el-icon-search" v-model="input" placeholder="输入平台名称、服务关键词进行检索"></el-input> -->
						<p>
							搜索“家政”、“物流”、“软件开发”关键词
						</p>
					</div>
				</div>


				<div class="dropDown" v-if="isMobile">
					<div class="tab-head-right">
						<div class="tab-1" @click="clickTab('全部')" :class="{ 'cTab': tabName === '全部' }">
							<!-- <img src="../../public/home/all.png" alt=""> -->
							全部
						</div>
						<div class="tab-1" v-for="( item, index ) in  tabList " :class="{ 'cTab': item.name == tabName }"
							:key="index" @click="clickTab(item.name)">
							<!-- <img :src="item.icon" alt="" v-if="item.name === '热门推荐'"> -->
							{{ item.name }}
						</div>
					</div>
				</div>
				<div class="tab-content">

					<div class="tab-content-card" v-if="SlList && SlList.length!=0">
						<div class="card-item" v-for="( item, index ) in  SlList " :key="index">
							<div class="item-title">
								<div class="t-text">
									<img :src="item.icon" alt="" v-if="!isMobile">
									{{ item.name }}
								</div>

								<div class="notice" v-if="index === 0">
									<HelloWorld></HelloWorld>
								</div>
							</div>
							<div class="item-content">
								<div class="item-content-1" v-for="( ite, index ) in  item.list " :key="index"
									@click="clickAddress(ite.url)">
									<div class="content-1-top">
										<img :src="ite.logo" :title="ite.desc" :alt="ite.desc">
										<span>{{ ite.name }}</span>
									</div>
									<div class="content-1-bottom">
										{{ ite.desc }}
									</div>
								</div>
							</div>

						</div>
						<div class="" style="height: 100px;width: 100%;"></div>
					</div>
					<el-empty v-else description="还没有结果..."></el-empty>

				</div>
			</div>


		</div>

		<!-- <div v-if="guanggaolist.length" class="side">
			<div v-for="(item, index) in guanggaolist" :key="index">
				<img :src="item.logo_image" alt="" v-if="item.logo_image" @click="clickTp1(item.network_addr)">
				<div class="del" @click="del(index)">
					<i class="el-icon-error"></i>
				</div>
			</div>
		</div> -->

		<div class="backTop" @click="clickTop" v-show="fShow2">
			<img :src="ggTp2" alt="">
		</div>


	</div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue"
import {
	getAll,
	getLb,
	getGgLb
} from "../api/all.js"
export default {
	name: 'app',
	components: {
		HelloWorld
	},
	data() {
		return {
			tabList: [],
			tabName: '全部',
			fwNum: 0,
			fShow2: false,
			fShow: false,
			SlList: [],
			ggTp1: {},
			ggTp2: {},
			gg1: true,
			gg2: true,
			isMobile: null,
			guanggaolist: [],
			input: ''
		}
	},
	created() {
		this.isMobile = this.isMobiles()
	},
	async mounted() {
		await this.getTab()
		await this.getSl()
		await this.getGg()
	},
	methods: {
		isMobiles() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
		},

		clickTab(item) {



			if (item == '全部') {
				getLb()
					.then(res => {
						console.log(res.data)
						this.SlList = res.data
					})
			} else {
				getLb({
					type: item
				})
					.then(res => {
						this.SlList = res.data
					})
			}

			this.tabName = item

		},
		clickAddress(add) {
			window.open(add, '_blank')
		},
		//滚动监听
		handleScroll() {

			fwNum = document.documentElement.scrollTop

			let mzzb_home_page = localStorage.getItem("mzzb_home_page");

			if (this.fwNum > 2500 && !mzzb_home_page) {
				this.fShow = true
				this.fShow2 = true
			} else if (fwNum > 2500 && mzzb_home_page) {
				this.fShow = false
				this.fShow2 = true
			} else {
				this.fShow = false
				this.fShow2 = false
			}
		},
		//点击返回顶部
		clickTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		},
		getTab() {
			getAll()
				.then(res => {
					this.tabList = res.data
				})
		},
		getSl() {
			getLb()
				.then(res => {
					this.SlList = res.data
				})
		},
		async getGg() {
			await getGgLb()
				.then(res => {
					if (res.data) {
						this.guanggaolist = res.data
					}
				})
		},
		clickTp1(url) {
			window.open(url)
		},
		clickTp2() {
			window.open(this.ggTp2.url, '_blank')
		},
		del(index) {
			// 检查索引是否在数组范围内
			if (index >= 0 && index < this.guanggaolist.length) {
				this.guanggaolist.splice(index, 1); // 删除指定索引的元素
			} else {

			}
		},
		del2() {
			this.gg2 = false
		},
		fhTp(obj) {
			return obj.logo_image
		},
		searchName() {
			console.log('点击搜索')
			if (this.input !== '') {
				getLb({
					searchName: this.input
				})
					.then(res => {
						this.SlList = res.data
					})
			} else {
				getLb()
					.then(res => {
						this.SlList = res.data
					})
			}

		}
	}
}
</script>

<style scoped lang="less">
/* 媒体查询：PC端 */
@media screen and (min-width: 601px) {
	.home {
		overflow-y: hidden;
		min-height: 100vh;
		background-color: #f5f5f5;
		// padding: 0 10%;
		box-sizing: border-box;
	}



	.main {

		display: flex;
		justify-content: center;
		margin-top: 15px;

		&-left {
			width: 14%;
			// margin-left: 2%;
		}

		&-right {
			width: 86%;


			.head-1 {
				height: 273px;
				background-image: url('../../public/home/headBack.jpg');
				background-repeat: no-repeat;
				background-size: 100% 258px;
				overflow: hidden;
				font-size: 40px;
				color: #fff;
				font-weight: bold;
				border-radius: 10px;
				margin-bottom: 10px;

				>div {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 120px;
				}

				.el-input {
					width: 23%;
				}



				p {
					font-size: 16px;
					color: #005EFF;
				}


				/deep/.el-input__inner {
					border-radius: 26px 0 0 26px;
					height: 52px;
				}

				/deep/ .el-input-group__append,
				.el-input-group__prepend {
					border-radius: 0 26px 26px 0;
					background-color: #fff;
					border: none;
				}

			}

			.head-2 {

				margin-bottom: 20px;
				display: flex;
				justify-content: space-between;



				.img {
					width: 33%;
					position: relative;
					cursor: pointer;
				}

				.infomercial {
					width: 41px;
					height: 19px;
					text-align: center;
					line-height: 19px;
					font-size: 12px;
					border-radius: 4px;
					background-color: #fff;
					position: absolute;
					right: 8px;
					top: 8px;
				}

				img {
					width: 100%;
					height: 200px;
					position: relative;
					border-radius: 10px;
				}
			}
		}



		.tab-head {
			width: 13%;
			line-height: 44px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0 10px;
			// margin-right: 20px;
			margin-left:5px;
			border-radius: 14px;
			border-bottom: 1px solid #E7E7E7;
			position: fixed;


			&-right {
				font-size: 18px;
				color: #464646;
				overflow-y: auto;
				height: 61vh;

				>div:hover {
					background: rgba(62, 169, 246, 0.15);
					color: #000;
				}

				>div {
					height: 44px;
					margin-top: 15px;
					width: 100%;
					font-size: 18px;
					cursor: pointer;
					display: flex;
					border-radius: 8px;
					align-items: center;
				}



				img {
					width: 24px;
					height: 24px;
					margin: 0 10px;
				}

				.cTab {
					background: #3EA9F6;
					// border-bottom: 2px solid #0331A0;
					color: #fff;
				}

				#cTab {
					background: #3EA9F6;
					// border-bottom: 2px solid #0331A0;
					color: #fff;
				}
			}


		}

		.tab-content {
			box-sizing: border-box;
			padding: 10px;
			padding-left: 30px;
			border-radius: 10px;
			width: 100%;
			background-color: #fff;


			&-card {
				.card-item {
					margin-bottom: 30px;
				}

				.item-title {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 22px;
					color: #3D3D3D;
					font-weight: 500;
					margin-bottom: 20px;

					.t-text {
						display: flex;
						align-items: center;
					}

					img {
						width: 28px;
						height: 28px;
						margin-right: 7px;
					}

					.notice {
						width: 500px;
					}
				}

				.item-content {
					display: flex;
					flex-wrap: wrap;
					margin-left: 15px;

					>div {
						width: 214px;
						height: 122px;
						box-shadow: 1px 1px 5px 2px #DBDBDB;
						background-color: #FBFCFD;
						margin-bottom: 20px;
						flex-shrink: 0;
						cursor: pointer;
						margin-right: 30px;
						border-radius: 12px;
					}

					.content-1-top {
						width: 199px;
						margin-top: 16px;
						display: flex;
						margin-bottom: 16px;

						align-items: center;
						font-size: 18px;



					}

					.content-1-bottom {

						font-size: 12px;
						color: #898989;
						box-sizing: border-box;
						padding: 0 10px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						/* 显示的行数 */
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					.item-content-1:hover {
						transition: all .25s ease-in;
						transform: translateY(-15px);
						background: #3EA9F6;
						color: #fff;

						.content-1-bottom {
							color: #fff;
						}
					}

					img {
						width: 36px;
						height: 36px;
						margin: 0 30px;
					}
				}
			}
		}
	}

	.demo-tabs>.el-tabs__content {
		padding: 32px;
		color: #6b778c;
		font-size: 32px;
		font-weight: 600;
	}

	.backTop {
		position: fixed;
		right: 80px;
		top: 75%;
		z-index: 10;

		img {
			width: 40px;
			height: 40px;
		}
	}

	.side {
		position: fixed;
		right: 15px;
		top: 30%;
		display: flex;
		flex-direction: column;
		z-index: 5;
		cursor: pointer;

		>div {
			position: relative;
		}

		.del {
			position: absolute;
			top: -8px;
			right: -8px;
		}

		i {
			width: 16px;
			height: 16px;
		}

		img {
			width: 187px;
			height: 117px;
			margin-bottom: 30px;
			border-radius: 10px;
		}
	}
}

/* 媒体查询：手机端 */
@media screen and (max-width: 600px) {

	.home {
		overflow-y: hidden;
		// min-height: 100vh;
		background-color: #f5f5f5;
		box-sizing: border-box;
	}



	.main {

		display: flex;
		justify-content: center;

		&-right {
			width: 100%;

			.head-1 {
				height: 151px;
				background-image: url('../../public/home/headBack.jpg');
				background-repeat: no-repeat;
				background-size: 100% 151px;
				overflow: hidden;
				font-size: 40px;
				color: #fff;
				font-weight: bold;

				>div {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 60px;
				}

				.el-input {
					width: 75%;
				}

				p {
					font-size: 16px;

					color: #005EFF;
				}


				/deep/.el-input__inner {
					border-radius: 26px 0 0 26px;
					height: 38px;
				}

				.el-input--suffix {
					font-size: 12px;
				}

				/deep/ .el-input-group__append,
				.el-input-group__prepend {
					border-radius: 0 26px 26px 0;
					background-color: #fff;
					border: none;
				}

			}

			.head-2 {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;

				img {
					width: 32%;
					height: 200px;
				}
			}
		}



		.tab-head {
			width: 10%;
			line-height: 44px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0 10px;
			margin-right: 20px;
			border-radius: 14px;
			border-bottom: 1px solid #E7E7E7;


			&-right {
				display: flex;
				font-size: 18px;
				color: #464646;
				overflow: hidden;
				display: flex;
				overflow-x: auto;
				box-sizing: border-box;
				padding-left: 20px;

				>div {
					height: 44px;
					// margin-top: 20px;
					flex-shrink: 0;
					font-size: 18px;
					cursor: pointer;
					margin-right: 21px;
					display: flex;
					border-radius: 8px;
					align-items: center;
				}

				img {
					width: 24px;
					height: 24px;
					margin: 0 10px;
				}

				.cTab {

					color: #000;
					font-weight: bold;
				}
			}


		}

		.tab-content {
			box-sizing: border-box;
			padding: 10px;
			width: 100%;
			height: 65vh;
			overflow-y: auto;
			// background-color: #fff;


			&-card {
				.card-item {
					margin-bottom: 10px;
					background-color: #fff;
					border-radius: 12px;
				}

				.item-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 16px;
					box-sizing: border-box;
					padding-left: 18px;
					color: #3D3D3D;
					font-weight: bold;
					margin-bottom: 20px;
					border-bottom: 0.5px solid #D4DCE1;
					height: 48px;
					overflow: hidden;

					img {
						width: 28px;
						height: 28px;
						margin-right: 7px;
					}

					.t-text {
						display: flex;
						align-items: center;
					}

					img {
						width: 28px;
						height: 28px;
						margin-right: 7px;
					}

					.notice {
						width: 70%;
						margin-right: -40px;

					}
				}

				// .item-title {
				// 	width: 100%;
				// 	display: flex;
				// 	align-items: center;
				// 	justify-content: space-between;
				// 	font-size: 22px;
				// 	color: #3D3D3D;
				// 	font-weight: 500;
				// 	margin-bottom: 20px;

				// 	.t-text {
				// 		display: flex;
				// 		align-items: center;
				// 	}

				// 	img {
				// 		width: 28px;
				// 		height: 28px;
				// 		margin-right: 7px;
				// 	}

				// 	.notice {
				// 		width: 500px;
				// 	}
				// }

				.item-content {
					display: flex;
					flex-wrap: wrap;

					>div {
						width: 50%;
						height: 122px;
						// box-shadow: 1px 1px 5px 2px #DBDBDB;
						// background-color: #FBFCFD;
						margin-bottom: 10px;
						flex-shrink: 0;
						cursor: pointer;
						border-radius: 12px;
					}

					.content-1-top {
						width: 199px;
						margin-top: 16px;
						display: flex;
						margin-bottom: 16px;
						font-weight: bold;
						align-items: center;
						font-size: 14px;



					}

					.content-1-bottom {

						font-size: 12px;
						color: #898989;
						box-sizing: border-box;
						padding: 0 10px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						/* 显示的行数 */
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					.item-content-1:hover {
						transition: all .25s ease-in;
						transform: translateY(-15px);
						background: #3EA9F6;
						color: #fff;

						.content-1-bottom {
							color: #fff;
						}
					}

					img {
						width: 36px;
						height: 36px;
						margin: 0 15px;
					}
				}
			}
		}
	}

	.demo-tabs>.el-tabs__content {
		padding: 32px;
		color: #6b778c;
		font-size: 32px;
		font-weight: 600;
	}

	.backTop {
		position: fixed;
		right: 80px;
		top: 75%;
		z-index: 10;

		img {
			width: 40px;
			height: 40px;
		}
	}

	.side {
		position: fixed;
		right: 15px;
		top: 30%;
		display: flex;
		flex-direction: column;
		z-index: 5;
		cursor: pointer;

		>div {
			position: relative;
		}

		.del {
			position: absolute;
			top: -8px;
			right: -8px;
		}

		i {
			width: 16px;
			height: 16px;
		}

		img {
			width: 187px;
			height: 117px;
			margin-bottom: 30px;
			border-radius: 10px;
		}
	}
}
</style>

