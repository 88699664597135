<template>
  <div class="box">
    <img src="../../public/home/notice.png" alt="">
    <div v-if="isMobile" class="textBox" style="left:0px;">

      <transition name="slide">
        <span v-if="isMobile" class="text" :key="text.id">{{ text.val }}</span>
        <p v-if="!isMobile" class="text" :key="text.id">{{ text.val }}</p>
      </transition>
    </div>
	
	<div v-if="!isMobile" class="textBox" style="left:-25px;">
	
	  <transition name="slide">
	    <span v-if="isMobile" class="text" :key="text.id">{{ text.val }}</span>
	    <p v-if="!isMobile" class="text" :key="text.id">{{ text.val }}</p>
	  </transition>
	</div>
	
	
  </div>
</template>

<script>
import { getNotice } from '../api/all'
export default {
  name: 'scroll',
  data() {
    return {
      textArr: [

      ],
      number: 0,
      isMobile: null,
    }
  },
  created() {
    this.isMobile = this.isMobiles()
  },
  computed: {
    text() {
      return {
        id: this.number,
        val: this.textArr[this.number]
      }
    }
  },
  mounted() {
    this.startMove()
    this.getNoticeList()
  },
  methods: {
    startMove() {
      // eslint-disable-next-line
      let timer = setTimeout(() => {
        if (this.number === this.textArr.length-1) {
          this.number = 0;
        } else {
          this.number += 1;
        }
        this.startMove();
      }, 2500); // 滚动不需要停顿则将2000改成动画持续时间
    },
    getNoticeList() {
      getNotice()
        .then(res => {
          for (let index = 0; index < res.data.length; index++) {
            this.textArr.push(res.data[index].title)
          }
  
        })
    },
    isMobiles() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 601px) {

  .box {
    display: flex;
    align-items: center;
    background-color: #fff;

    img {
      width: 25px;
      height: 20px;
      margin: 0 20px;
    }
  }

  .textBox {
    width: 80%;
    height: 40px;
    margin: 0 auto;
    padding: 7px 0;
    margin-top: 12px;
    overflow: hidden;
    position: relative;
    /* text-align: center; */
  }

  .text {
    width: 90%;
    position: absolute;
    bottom: 0;
  }

  .slide {
    display: flex;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s linear;
  }

  .slide-enter {
    transform: translateY(20px) scale(1);
    opacity: 1;
  }

  .slide-leave-to {
    transform: translateY(-20px) scale(0.8);
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {

  .box {
    display: flex;
    align-items: center;
    background-color: #fff;
    img {
      width: 14px;
      height: 12px;
      margin-right: 10px;
    }
  }

  .textBox {
    width: 80%;
    font-size: 11px;
    padding: 7px 0;

    overflow: hidden;
    position: relative;
    /* text-align: center; */
  }

  .text {
    width: 90%;
    position: absolute;
    bottom: 0;
  }

  .slide {
    display: flex;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s linear;
  }

  .slide-enter {
    transform: translateY(20px) scale(1);
    opacity: 1;
  }

  .slide-leave-to {
    transform: translateY(-20px) scale(0.8);
    opacity: 0;
  }
}
</style>
