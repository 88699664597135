<template>
	<div class="box">
		<!-- <div class="box_left"></div> -->
		<div class="box_middle">
			<div class="middle-title">
				收录申请
			</div>

			<el-form :label-position="labelPosition" label-width="80px" :model="form" :rules="rules" ref="form">
				<el-form-item class="labName" prop="name">
					<span slot="label">
						<span class="labName" style="color: #000"><strong>网站名称：</strong> </span>
					</span>
					<el-input placeholder="请输入网站名称" maxlength="10" v-model="form.name">
						<el-button style="background-color: #0331A0;color: #fff;" @click="clickCx()"
							slot="append">查询</el-button>
					</el-input>
				</el-form-item>
				<el-form-item prop="address">
					<span slot="label">
						<span class="labName" style="color: #000"><strong>推广网站：</strong> </span>
					</span>
					<el-input placeholder="请输入推广网站" maxlength="100" v-model="form.address"></el-input>
				</el-form-item>
				<div class="formIpt">
					<div class="formIpt-1">
						<el-form-item prop="qq">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>QQ：</strong> </span>
							</span>
							<el-input placeholder="请输入QQ号码" maxlength="15" v-model="form.qq"></el-input>
						</el-form-item>
					</div>
					<div class="formIpt-1">
						<el-form-item prop="email">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>Email：</strong> </span>
							</span>
							<el-input placeholder="请输入Email" maxlength="30" v-model="form.email"></el-input>
						</el-form-item>
					</div>
					<div class="formIpt-1">
						<el-form-item prop="phone">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>电话：</strong> </span>
							</span>
							<el-input placeholder="请输入电话号码" maxlength="11" v-model="form.phone"></el-input>
						</el-form-item>
					</div>
					<div class="formIpt-1">
						<el-form-item prop="type">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>推荐分类：</strong> </span>
							</span>
							<el-input placeholder="请输入推荐分类" maxlength="30" v-model="form.type"></el-input>
						</el-form-item>
					</div>
					<div class="formIpt-1">
						<el-form-item prop="time">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>建站时间：</strong> </span>
							</span>
							<el-input placeholder="请输入建站时间" maxlength="30" v-model="form.time"></el-input>
						</el-form-item>
					</div>
					<div class="formIpt-1">
						<el-form-item prop="num">
							<span slot="label">
								<span class="labName" style="color: #000"><strong>日访问量：</strong> </span>
							</span>
							<el-input placeholder="请输入日访问量" maxlength="10" v-model="form.num"></el-input>
						</el-form-item>
					</div>
				</div>

				<el-form-item prop="synopsis">
					<span slot="label">
						<span class="labName" style="color: #000"><strong>网站描述：</strong> </span>
					</span>
					<el-input placeholder="请输入网站描述" maxlength="100" v-model="form.synopsis"
						:autosize="{ minRows: 4, maxRows: 4 }" type="textarea"></el-input>
				</el-form-item>

				<el-form-item>
					<span slot="label">
						<span class="labName" style="color: #000"><strong>上传LOGO：
								<span style="color: #F44F4F;">(宽高比列1:1)</span>
							</strong> </span>
					</span>
					<el-upload class="avatar-uploader" action="http://148.70.177.170:8089/uploadManager/upload"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item>
					<span slot="label">
						<span class="labName" style="color: #000"><strong>给众包网的意见：</strong> </span>
					</span>
					<el-input placeholder="给众包网的意见" maxlength="100" v-model="form.suggestion"
						:autosize="{ minRows: 4, maxRows: 4 }" type="textarea"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button class="tj" @click="submitForm('form')">提交</el-button>
				</el-form-item>
				<div class="fh" @click="goHome()">
					<span>返回首页</span>
				</div>

			</el-form>
			<div style="width: 100%;height: 140px;"></div>

		</div>
		<div class="box_right" v-if="!isMobile">
			<div class="right-title">
				提示
			</div>
			<div class="right-text">
				<strong>优先原则：</strong>
				如果您的网站设置了众包网的文字或图片友情链接，我们将会优先考虑您的收录申请。
			</div>
			<div class="right-title2">
				<strong>网站名称：</strong>
				输入网站名称后点击“查询”按钮，查看名称是否已被收录。
			</div>
			<div class="right-text">
				<strong>友情链接：</strong>
				链接方式有两种，请参考以下代码：
			</div>
			<div class="right-title2">
				<p v-text="wz"></p>
			</div>
			<div class="right-text">
				<p v-text="wz2"></p>
			</div>
			<div class="img">
				<img src="../../public/home/logo.png" style="width:140px;height: 81px;" alt="">
			</div>
			<div class="right-text">
				<strong>推荐分类：</strong>
				如果没有合适分类，请输入“无”，并在给众包网意见备注您期望的分类。
			</div>
		</div>
	</div>
</template>

<script>

import {
	slsq,
	slyz
} from "../api/all.js"

export default {

	data() {
		return {
			form: {
				address: '', //推广网址
				name: '', //网站名称
				qq: '',
				email: '', //邮箱
				phone: '', //手机号码
				classify: '', //推荐分类
				time: '', //建站时间
				num: '', //日访问量
				synopsis: '', //网站描述
				suggestion: '', //网站建议
				imageUrl: '' //图片地址
			},
			rules: {
				address: [{
					required: true,
					message: '请输入推广网站',
					trigger: 'blur'
				}],
				name: [{
					required: true,
					message: '请输入网站名称',
					trigger: 'blur'
				},
				{
					min: 2,
					max: 10,
					message: '长度在 2 到 10 个字符',
					trigger: 'blur'
				}
				],
				qq: [{
					required: true,
					message: '请输入QQ号码',
					trigger: 'blur'
				}],
				email: [{
					required: true,
					message: '请输入Email',
					trigger: 'blur'
				}],
				phone: [{
					required: true,
					message: '请输入手机号码',
					trigger: 'blur'
				}],
				time: [{
					required: true,
					message: '请输入建站时间',
					trigger: 'blur'
				}],
				num: [{
					required: true,
					message: '请输入日访问量',
					trigger: 'blur'
				}],
				synopsis: [{
					required: true,
					message: '请输入网站描述',
					trigger: 'blur'
				}],
				type: [{
					required: true,
					message: '请输入推荐分类',
					trigger: 'blur'
				}],
				resource: [{
					required: true,
					message: '请选择活动资源',
					trigger: 'change'
				}]

			},
			labelPosition: 'top',
			wz: '① 以文字方式链接，需填入: <a href="http://www.zhongbaowang.org.cn">众包网</a>',
			wz2: ` ② 以图片方式链接，需填入: <a href="http://www.zhongbaowang.org.cn"><img src="http://zhongbapwang.org.cn/img/l0g0.558477ec.png" alt="众包网"/></a>`,
			isMobile: null
		}
	},
	created() {
		this.isMobile = this.isMobiles()
	},
	mounted(){
		window.scrollTo(0, 0); // 滚动到页面顶部
	},
	methods: {
		isMobiles() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
		},
		handleAvatarSuccess(res, file) {
			this.form.imageUrl = res.pic;
		},
		beforeAvatarUpload(file) {
			console.log(file.type)
			const isJPG = file.type === 'image/jpeg' || 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isJPG) {
				this.$message.error('上传LOGO图片只能是JPG或PNG格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传LOGO图片大小不能超过 2MB!');
			}
			return isJPG && isLt2M;
		},
		clickCx() {
			let that = this
			if (this.form.name) {
				slyz({
					name: this.form.name
				})
					.then(res => {
						if (res.status == 1) {
							this.$message.success(res.msg)
						} else {
							this.$message.error(res.msg)
						}
					})
			} else {
				this.$message.error('请输入网站名称')
			}
		},
		submitForm(formName) {
			let that = this
			this.$refs[formName].validate((valid) => {
				if (!this.form.imageUrl) {
					this.$message.error('请上传网站LOGO')
					return
				}


				if (valid) {
					slsq({
						name: this.form.name,
						network_addr: this.form.address,
						qq: this.form.qq,
						phone: this.form.phone,
						email: this.form.email,
						type: this.form.type,
						create_time: this.form.time,
						network_desc: this.form.synopsis,
						vistor_count: this.form.num,
						network_propose: this.form.suggestion,
						logo_image: this.form.imageUrl
					})
						.then(res => {
							if (res.status == 1) {
								this.$message.success(res.msg)

								this.$refs[formName].resetFields();
							} else {
								this.$message.error(res.msg)
							}
						})
				}
			});
		},
		goHome() {
			this.$router.push({
				name: 'home'
			})
		}
	},

}
</script>

<style scoped lang="less">
@media only screen and (min-width: 1029px) {

	//这里写pc客户端的样式
	.box {
		min-height: 100vh;
		width: 100%;
		background-color: #f5f5f5;
		display: flex;
		justify-content: center;
		padding: 0 20px;
		box-sizing: border-box;
		padding-top: 30px;
		overflow-y: hidden;

		&_left {
			width: 442px;
			height: 436px;

		}

		&_middle {
			width: 778px;
			min-height: 1093px;
			background-color: #fff;
			margin: 0 40px;
			border-radius: 20px;
			box-sizing: border-box;
			padding: 40px;
			font-size: 32px;
			font-weight: 500;

			.middle-title {
				text-align: center;
				margin-bottom: 40px;
			}

			.el-form {

				.el-form-item_label {
					font-size: 18px !important;
				}

				.labName {
					font-size: 16px;
					margin-bottom: 10px;
				}

				.tj {
					width: 100%;
					background-color: #013299;
					color: #fff;
					height: 38px;
				}



				.el-form-item {
					.avatar-uploader .el-upload {
						border: 1px dashed #d9d9d9;
						border-radius: 6px;
						cursor: pointer;
						position: relative;
						overflow: hidden;
					}

					.avatar-uploader .el-upload:hover {
						border-color: #409EFF;
					}

					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width: 178px;
						height: 178px;
						line-height: 178px;
						text-align: center;
						border: 1px dashed #d9d9d9;
					}

					.avatar {
						width: 178px;
						height: 178px;
						display: block;
					}
				}

			}

			.fh {
				font-size: 16px;
				color: #9E9E9E;
				text-align: center;
				width: 100%;
			}

			.formIpt {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&-1 {
					width: 49%;
					flex-shrink: 0;

				}

				.el-select {
					width: 100%;
				}
			}

		}

		&_right {
			width: 542px;
			height: 700px;
			background-color: #fff;
			border-radius: 20px;
			box-sizing: border-box;
			padding: 40px;
			font-size: 18px;
			// letter-spacing: 1px;

			p{
				overflow-wrap: break-word; /* 允许长单词换行 */
			}

			.right-title {
				font-size: 26px;
				font-weight: 500;
				margin-bottom: 15px;
				color: #FF6F2C;
				font-weight: bold;
			}

			.right-title2 {
				font-weight: 500;
				margin-bottom: 15px;
				color: #3D3D3D;
			}

			strong {
				color: #013299
			}

			.right-text {

				color: #3D3D3D;
				margin-bottom: 20px;
				position: relative;
				z-index: 2;
			}

			.img{
				text-align: center;
				margin: 20px 0;
			}
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 480px) {

	//在这里写小屏幕设备的样式
	.box {
		min-height: 100vh;
		width: 100%;
		background-color: #f5f5f5;
		justify-content: center;
		box-sizing: border-box;
		overflow-y: hidden;


		&_middle {
			width: 100%;
			min-height: 1093px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 20px;
			font-size: 32px;
			font-weight: 500;

			.middle-title {
				text-align: center;
				margin-bottom: 20px;
			}

			.el-form {

				.el-form-item_label {
					font-size: 18px !important;
				}

				.labName {
					font-size: 16px;
					margin-bottom: 10px;
				}

				.tj {
					width: 100%;
					background-color: #013299;
					color: #fff;
					height: 38px;
				}



				.el-form-item {
					.avatar-uploader .el-upload {
						border: 1px dashed #d9d9d9;
						border-radius: 6px;
						cursor: pointer;
						position: relative;
						overflow: hidden;
					}

					.avatar-uploader .el-upload:hover {
						border-color: #409EFF;
					}

					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width: 178px;
						height: 178px;
						line-height: 178px;
						text-align: center;
						border: 1px dashed #d9d9d9;
					}

					.avatar {
						width: 178px;
						height: 178px;
						display: block;
					}
				}

			}

			.fh {
				font-size: 16px;
				color: #9E9E9E;
				text-align: center;
				width: 100%;
			}

			.formIpt {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&-1 {
					width: 49%;
					flex-shrink: 0;

				}

				.el-select {
					width: 100%;
				}
			}

		}

		&_right {
			width: 442px;
			height: 600px;
			background-color: #fff;
			border-radius: 20px;
			box-sizing: border-box;
			margin: 37px;
			padding: 40px;

			.right-title {
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 15px;
				color: #3D3D3D;
			}

			.right-title2 {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 15px;
				color: #3D3D3D;
			}

			.right-text {
				font-size: 14px;
				color: #3D3D3D;
				margin-bottom: 20px;
				position: relative;
				z-index: 2;
			}

			img {
				height: 149px;
				width: 199px;
				margin-top: -17px;
			}
		}
	}
}
</style>