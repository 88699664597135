<template>
  <div class="box">



    <div class="box_middle">
      <div class="middle-title">
        留言反馈
      </div>

      <el-form :label-position="labelPosition" label-width="80px" :model="form" :rules="rules" ref="form">
        <el-form-item prop="address">
          <span slot="label">
            <span class="labName" style="color: #000"><strong>反馈标题：</strong> </span>
          </span>
          <el-input placeholder="请输入反馈标题" maxlength="100" v-model="form.address"></el-input>
        </el-form-item>


        <el-form-item prop="synopsis">
          <span slot="label">
            <span class="labName" style="color: #000"><strong>反馈描述：</strong> </span>
          </span>
          <el-input placeholder="请详细描述您的反馈" maxlength="100" v-model="form.synopsis" :autosize="{ minRows: 4, maxRows: 4 }"
            type="textarea"></el-input>
        </el-form-item>

        <el-form-item>  
          <span slot="label">
            <span class="labName" style="color: #000"><strong>截图：
              </strong> </span>
          </span>
          <div class="upload">
            <div class="uoload-1">
              <el-upload class="avatar-uploader" :multiple="true" action="http://148.70.177.170:8089/uploadManager/upload"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>

            <img v-for="item in form.imageUrl" :src="item" v-if="form.imageUrl && form.imageUrl.length != 0"
              class="avatar">
          </div>

        </el-form-item>

        <el-form-item>
          <el-button class="tj" @click="submitForm('form')">提交</el-button>
        </el-form-item>
        <div class="fh" @click="goHome()">
          <span>返回首页</span>
        </div>

      </el-form>
      <div style="width: 100%;height: 100px;"></div>
    </div>
  </div>
</template>

<script>
import {
  tjjj
} from "../api/all.js"
export default {
  data() {
    return {
      form: {
        address: '', //反馈标题
        synopsis: '', //反馈详情
        imageUrl: [] //图片地址
      },
      rules: {
        address: [{
          required: true,
          message: '请输入反馈标题',
          trigger: 'blur'
        }],
        synopsis: [{
          required: true,
          message: '请输入您的反馈',
          trigger: 'blur'
        }],


      },
      labelPosition: 'top',

    }
  },
  mounted() {
    window.scrollTo(0, 0); // 滚动到页面顶部
   },
  methods: {
    beforeAvatarUpload(file) {
      console.log(file.type)
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传LOGO图片只能是JPG或PNG格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传LOGO图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file) {
      this.form.imageUrl.push(res.pic);
    },
    submitForm(formName) {
      let that = this
      this.$refs[formName].validate((valid) => {
        if (!this.form.imageUrl) {
          this.$message.error('请上传网站LOGO')
          return
        }


        if (valid) {
          tjjj({
            name: this.form.address,
            desc: this.form.synopsis,
            images: this.form.imageUrl
          })
            .then(res => {
              if (res.status == 1) {
                this.$message.success(res.msg)

                this.$refs[formName].resetFields();
                this.form.imageUrl = []
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      });
    },
    goHome() {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang='less' scoped>
/* 媒体查询：手机端 */
@media screen and (max-width: 600px) {
  .box {
    min-height: 79vh;
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: hidden;

    &_left {
      width: 442px;
      height: 436px;

    }

    &_middle {
      width: 100%;
      min-height: 79vh;
      background-color: #fff;
      box-sizing: border-box;
      padding: 20px;
      font-size: 32px;
      font-weight: 500;

      .middle-title {
        text-align: center;
        margin-bottom: 20px;
        color: #013299;
      }

      .el-form {

        .el-form-item_label {
          font-size: 18px !important;
        }

        .labName {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .tj {
          width: 100%;
          background-color: #013299;
          color: #fff;
          height: 38px;
        }



        .el-form-item {

          .upload {
            display: flex;
            flex-wrap: wrap;
            >div{
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }

          .imgList {
            display: flex;
            flex-wrap: wrap;
          }



          .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            border: 1px dashed #d9d9d9;
          }

          .avatar {
            width: 100px;
            height: 100px;
            display: block;
          }
        }

      }

      .fh {
        font-size: 16px;
        color: #9E9E9E;
        text-align: center;
        width: 100%;
      }

      .formIpt {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-1 {
          width: 49%;
          flex-shrink: 0;

        }

        .el-select {
          width: 100%;
        }
      }

    }

    &_right {
      width: 542px;
      height: 600px;
      background-color: #fff;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 40px;

      .right-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #3D3D3D;
      }

      .right-title2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #3D3D3D;
      }

      .right-text {
        font-size: 14px;
        color: #3D3D3D;
        margin-bottom: 20px;
        position: relative;
        z-index: 2;
      }

      img {
        height: 149px;
        width: 199px;
        margin-top: -17px;
      }
    }
  }
}

/* 媒体查询：PC端 */
@media only screen and (min-width: 1029px) {

  //这里写pc客户端的样式
  .box {
    min-height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    padding-top: 30px;
    overflow-y: hidden;

    &_left {
      width: 442px;
      height: 436px;

    }

    &_middle {
      width: 778px;
      min-height: 1093px;
      background-color: #fff;
      margin: 0 40px;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 40px;
      font-size: 32px;
      font-weight: 500;

      .middle-title {
        text-align: center;
        margin-bottom: 40px;
      }

      .el-form {

        .el-form-item_label {
          font-size: 18px !important;
        }

        .labName {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .tj {
          width: 100%;
          background-color: #013299;
          color: #fff;
          height: 38px;
        }



        .el-form-item {

          .upload{
            display: flex;
            flex-wrap: wrap;
            >div{
              margin-right: 10px;
              margin-bottom: 10px;
            }
            
            img{
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
          .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          .avatar-uploader .el-upload:hover {
            border-color: #409EFF;

          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
            border: 1px dashed #d9d9d9;
          }

          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }
        }

      }

      .fh {
        font-size: 16px;
        color: #9E9E9E;
        text-align: center;
        width: 100%;
      }

      .formIpt {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-1 {
          width: 49%;
          flex-shrink: 0;

        }

        .el-select {
          width: 100%;
        }
      }

    }

    &_right {
      width: 542px;
      height: 600px;
      background-color: #fff;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 40px;

      .right-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #3D3D3D;
      }

      .right-title2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #3D3D3D;
      }

      .right-text {
        font-size: 14px;
        color: #3D3D3D;
        margin-bottom: 20px;
        position: relative;
        z-index: 2;
      }

      img {
        height: 149px;
        width: 199px;
        margin-top: -17px;
      }
    }
  }
}
</style>
